// variables
@import "../variables/_color-theme";
@import "../variables/_sizing";

input[type='email'],
input[type='text'],
input[type='password'] {
  position: relative;
  width: 300px;
  margin: 0 0 8px 0;
  display: block;
  border: none;
  border-bottom: 1px solid $green;
  text-align: left;
  padding: $padding_small 0;
  will-change: background-position;
  transition: all 0.3s cubic-bezier(.64, .09, .08, 1);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 96%, $green 96%);
  background-position: -300px 0;
  background-size: 300px 100%;
  background-repeat: no-repeat;
  color: $text_primary;

  &:focus {
    box-shadow: none;
    outline: none;
    background-position: 0 0;
  }
}

// label wrapper for checkbox
.mf-checkbox {
  position: relative;

  z-index: 1;

  vertical-align: middle;

  display: inline-block;

  margin: 0;
  padding: 0;
}

input[type='checkbox'] {

}

textarea {
  resize: vertical;
  width: 300px;
  margin: 0;
  display: block;
  text-align: left;
  border-radius: 0;
  &:focus {
    box-shadow: none;
    outline: none;
    outline: 0.114em solid $green;
  }
}

label {
  color: $text_secondary;
  cursor: pointer;
}

select {
  display: block;
  margin-bottom: 5px;
}

.form-group {
  .error-span {
    color: $red;
  }
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}
