/* Dropdown Button */
.dropdown-settings {

}

/* The container <div> - needed to position the dropdown content */
.dropdown-block {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  min-width: 298px;
  @include box_shadow(8);
}

/* Links inside the dropdown */
.dropdown-content a {
  color: $text_primary;
  padding: 12px 16px !important;
  text-decoration: none;
  display: block !important;
  height: auto !important;
  line-height: 1.2em;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover,
.dropdown-content a:focus,
.dropdown-content a:active {
  background-color: rgba($text_hint, 0.10) !important;
}

.dropdown-content {
  .divider {
    margin: 4px 0;
  }
}

// Show the dropdown menu
.dropdown-show {
  display: block;
}

// right to left dropdown for user-settings
.user-settings {
  .dropdown-content {
    right: 0;
    top: 60px;
    width: 100%;
    min-width: 160px;
  }
}

// right to left dropwon for context-nav
.context-nav {
  .dropdown-content {
    min-width: 220px;
    top: 8px;
    right: 60px;
  }
  input[type='checkbox'] {
    margin-top: 3px;
  }
  .divider {
    margin: 8px 0;
  }
  .disabled {
    color: $text_secondary !important;
    cursor: auto;
    &.text-primary {
      color: $primary_dark !important;
      font-weight: 500;
    }
    &.text-accent {
      color: $accent !important;
    }
    &:hover {
      background-color: white !important;
    }
  }
}

.budget-item-dropdown-wrapper {
  border-bottom: 1px solid #B6B6B6;
  margin-bottom: 0;

  span {
    display: inline-block;
    word-wrap: break-word;
    padding: 12px 6px;
    max-width: 90%;
  }

  i {
    margin-top: 10px;
  }
}
