.transactions {
  .trans-item {
    // line-height: 48px;
    position: relative;
    border-bottom: 0px solid $divider;
    cursor: pointer;
    // text-align: center;
    margin: 12px;

    @include select();

    span {
      padding: 8px;
      display: block;
      word-wrap: break-word;
    }
    i {
      font-size: 24px;
      position: absolute;
      right: 0;
      margin-top: -32px;
    }
    &:hover {
      background-color: rgba($text_hint, 0.20);
    }
    &:active, &:focus {
      background-color: rgba($text_hint, 0.20);
    }
    &.active {
      background-color: $primary-light;
      color: #fff;
      span {
        color: #fff;
      }
    }

    &.budget-item-filter {
      &:hover {
        background-color: #fff;

        .budget-item-dropdown-wrapper {
          background-color: rgba($text_hint, 0.20);
        }
      }
      &:active, &:focus {
        background-color: #fff;
      }
    }
  }
  .transactions-block {
    position: relative;
    width: 100%;
    padding: $padding_default $padding_default 68px $padding_default*2;
  }
  select {
    cursor: pointer;
  }
  table {
    cursor: pointer;
    width: 100%;
    position: relative;
    table-layout: fixed;
    word-wrap: break-word;
  }
  tr {
    &:hover {
      background-color: rgba($text_hint, 0.20);
    }
  }
  th {
    color: $text_secondary;
  }
  td {
    font-weight: 500;
  }
  td, th {
    text-align: left;
    padding: 12px;
    font-size: 14px;
    border-bottom: 1px solid $text_hint;
  }
  .show-more-trans {
    text-align: center;
    margin-top: 10px;
  }
  .transactions-add {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 68px;
    width: calc(100% - 16px);
    background-color: transparent;
    a {
      position: absolute;
      right: 16px;
      bottom: 16px;
    }
  }
}

#transactions_table {
  tr {
    td:nth-child(2) {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;

    }
    td:nth-child(6) {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    td:nth-child(3) {
      font-weight: bold;
      color: $primary;

    }
    td:nth-child(6) {
      color: $text-hint;
    }
  }

  .item-column {
    width: 20%;
  }

  .description-column {
    width: 100px;
  }
}

.header-wrapper {
  .header-text {
    display: inline-block;
    margin-bottom: 0;
  }

  .transaction-status-dropdown-trigger {
    display: inline-block;
    float: right;
    margin-top: 8px;

    .dropdown-trigger-btn {
      color: $text_primary;
    }
  }
}

.transaction-status {
  display: none;
}

.sub-item-filter {
  height: 24px;
  padding: 12px;
}

.budget-item-filter-selector {
  padding: 12px 6px !important;
}

.budget-item-filter {
  display: none;

  .dropdown-content {
    min-width: 276px;
  }
}