// Global
.container {
  width: 100%;
  padding: 0px;
  &::after {
    content: ' ';
    display: table;
    clear: both;
  }
}

.container-fix {
  max-width: 1200px;
  margin: 0 auto;
  &::after {
    content: ' ';
    display: table;
    clear: both;
  }
}

// Columns
.container > [class^='col-'],
.container-fix > [class^='col-'] {
  float: left;
  min-height: 1px;
  padding: 0px 12px;
}

[class^='col-'].no-padding {
  padding: 0px;
}

.col-10 {
  width: 10%;
}

.col-20 {
  width: 20% !important;
}

.col-30 {
  width: 30%;
}

.col-40 {
  width: 40%;
}

.col-45 {
  width: 45%;
}

.col-50 {
  width: 50%;
}

.col-60 {
  width: 60%;
}

.col-70 {
  width: 70%;
}

.col-80 {
  width: 80% !important;
}

.col-90 {
  width: 90%;
}

.col-95 {
  width: 95%;
}

.col-100 {
  width: 100%;
}

// offset
.col-offset-05 {
  margin-left: 5%;
}

.col-offset-10 {
  margin-left: 10%;
}

.col-offset-15 {
  margin-left: 15%;
}

.col-offset-20 {
  margin-left: 20%;
}

.col-offset-25 {
  margin-left: 25%;
}

.col-offset-30 {
  margin-left: 30%;
}

.col-offset-35 {
  margin-left: 35%;
}

.col-offset-40 {
  margin-left: 40%;
}

.col-offset-45 {
  margin-left: 45%;
}

.col-offset-50 {
  margin-left: 50%;
}

.col-offset-55 {
  margin-left: 55%;
}

.col-offset-60 {
  margin-left: 60%;
}

.col-offset-65 {
  margin-left: 65%;
}

.col-offset-70 {
  margin-left: 70%;
}

.col-offset-65 {
  margin-left: 65%;
}

.col-offset-80 {
  margin-left: 80%;
}

.col-offset-85 {
  margin-left: 85%;
}

.col-offset-90 {
  margin-left: 90%;
}

.col-offset-95 {
  margin-left: 95%;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}