//.fab{
//  border-radius: 50%;
//  margin:0;
//  padding: 20px;
//}
//
//.material{
//  position:relative;
//  color:white;
//  margin: 20px auto;
//  height:400px;
//  width:500px;
//  background:#f45673;
//
//}

.ripple {
  overflow: hidden;
}

.ripple-effect {
  position: absolute;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: white;

  animation: ripple-animation 0.5s;
}

@keyframes ripple-animation {
  from {
    transform: scale(1);
    opacity: 0.4;
  }
  to {
    transform: scale(100);
    opacity: 0;
  }
}