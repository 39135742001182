// =================================================
// Left block
// =================================================
.accounts {
  .left-block_top {
    li {
      position: relative;
    }
    i {
      position: absolute;
      right: $padding_default;
      top: 50%;
      margin-top: -12px;
    }
    .account-name, i {
      color: $text-primary;
    }
    .account-name,
    .account-count {
      display: inline-block;
      width: 50%;
      word-wrap: break-word;
      font-weight: bold;
    }
    .account-name {
      line-height: 1.1em;
    }
    .account-count {
      color: $primary;
      margin-top: $padding_small;
    }
  }
}

// =================================================
// Right block
// =================================================
.accounts {
  .account-block {
    padding: $padding_default*2 $padding_default $padding_default $padding_default*2;
    i {
      padding-right: $padding_small;
      vertical-align: bottom;
    }

    @include contentLists();
  }
  .account-title {
    .acc-name {
      font-size: $text_size_large;
      font-weight: 500;
    }
  }
  .account-owner,
  .account-permissions,
  .account-balance {
    p {
      margin: 0 0 5px 0;
    }
    .info {
      margin-left: 32px;
    }
    span {
      margin-left: -0.25em;
    }
  }
  .balance-table {
    margin-left: 32px;
    margin-top: 10px;
    color: $text_secondary;
    td {
      padding: 4px 0;
      min-width: 80px;
    }
    .sum {
      padding-left: 2px;
      color: $primary;
      font-weight: bold;
    }
  }
}

//
#accounts_new_account_currencyId_input {
  width: 300px;
  overflow: hidden;
}

.owner-name {
  line-height: 1.1em;
}

