@mixin contentLists() {
  ul {
    margin-top: 10px;
    li {
      list-style: none;
      margin-left: 32px;
      padding: 4px 0;
    }
  }
}

@mixin select() {
  select {
    outline: none;
    border-bottom: 0px;
    margin-top: 0;
    padding: 12px 6px;
    border-bottom: 1px solid #B6B6B6;
    width: 290px;
    &.active {
      background-color: $primary-light;
      color: #fff;
      span {
        color: #fff;
      }
    }
  }
}

@mixin editItemAmountsTable() {
  table {
    // table-layout: fixed;
    width: 100%;
  }
  tr {
    cursor: pointer;
    &:hover {
      background-color: rgba($text_hint, 0.20);
    }
  }
  th, td {
    text-align: center;

  }
  th {
    color: $text_secondary;
    padding: 6px 0;
  }
  td {
    padding: 8px 0;
    color: $primary;
    font-weight: bold;
    border-bottom: 1px solid #B6B6B6;
  }

  .delete {
    visibility: hidden;
  }

  .edit-amounts-table {
    tr:hover {

      .delete {
        visibility: visible;
      }
    }

    .planned-amount:hover {
      cursor: url("../../img/cursor_image.svg"), auto;
    }

    input {
      width: 60%;
      margin: 0 20%;
      text-align: center;
    }
  }

  .bottom-line {
    border-bottom: 2px solid #B6B6B6;
  }
}