.progress-group {
  cursor: pointer;
  padding: $padding_default/2 $padding_default $padding_default;

  &:first-child {
    margin-top: $padding_default/2;
  }
}

.progress-bar {
  position: relative;
  width: 100%;
  height: 8px;
  background-color: #ddd;
  margin-bottom: $padding_default;
}

.major-progress-bar {
  .progress-bar {
    height: 16px;
    background-color: #ddd;
    margin-bottom: 8px;
  }
}

.progress-bar.big {
  height: 20px;
  margin-bottom: 8px;
}

.progress-title {
  margin: 12px 24px 32px 32px;
}

.progress-title p:after {
  display: table;
  content: '';
  clear: both;
}

.progress-title p {
  color: $primary;
  margin: 4px 0;
}

.progress {
  z-index: 6;
  position: absolute;
  height: 100%;
  background-color: $primary;
}

.progress.blue {
  z-index: 6;
  background-color: $primary;
}

.progress.green {
  z-index: 5;
  background-color: $green;
}

.progress.red {
  z-index: 3;
  background-color: $red;
}

.progress.orange {
  z-index: 4;
  background-color: $orange;
}

.progress-status {
  position: absolute;
  height: 100%;
  background-color: transparent;
}

.progress-status:before {
  content: '';
  display: block;
  position: absolute;
  right: -5px;
  top: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $primary_dark;

}

.progress-status:after {
  content: '';
  display: block;
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid $primary_dark;

}

.status-red:before {
  border-top: 5px solid $red;
}

.status-red:after {
  border-bottom: 5px solid $red;
}

.status-green:before {
  border-top: 5px solid $green;
}

.status-green:after {
  border-bottom: 5px solid $green;
}
