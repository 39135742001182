.content__right-block.settings {
  padding: $padding_default $padding_default 0 $padding_default;
}

.settings {
  .acc-settings {
    padding: $padding_default;
    .title {
      font-weight: 700;
      font-size: $text_size_medium;
    }
    span {
      display: block;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
  .left-block_top {
    li {
      cursor: pointer;
    }
  }
  i {
    cursor: pointer;
  }
  .add-permission {
    .btn {
      min-width: 32px;
      bottom: 32px;
    }
  }
}

//
#currencies_settings_available_currencies_modal,
#planning_choose_currency_modal,
#edit_budget_item_choose_currency_region {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    cursor: pointer;
    padding: 8px;
    color: $primary-dark;
    &:hover {
      background-color: rgba($text_hint, 0.20);
    }
    &:active, &:focus {
      background-color: rgba($text_hint, 0.20);
    }
  }
  span {
    display: block;
  }
}

#settings_currency_settings_currencies_list {
  .child-group {
    margin-right: 24px;

    .up-wrap, .down-wrap, .clear-wrap {
      width: 24px;
    }
  }
}