// Basic size
// =========== 

$text_size_hint: 12px;

$text_size_small: 14px;

$text_size_medium: 16px;

$text_size_large: 20px;

$text_size_title: 34px;

$padding_default: 16px;

$padding_small: 8px;

$padding_tiny: 4px;

// SHADOWS
// =======

$shadow-key-umbra-opacity: 0.2 !default;
$shadow-key-penumbra-opacity: 0.14 !default;
$shadow-ambient-shadow-opacity: 0.12 !default;