// =================================================
// BUDGETS
// =================================================
.budgets {
  &.content__left-block {
    width: calc(55% - 32px);
    margin: $padding_default;
    height: auto;
    //  min-height: calc(100% - 32px);

    .btn {
      bottom: auto;
    }
  }

  &.content__right-block {
    width: calc(45% - 32px);
    right: 0;
    left: auto;
    margin: $padding_default;
    height: auto;
    //   min-height: calc(100% - 32px);
  }
  // home budget region
  .budget-block {
    padding: $padding_default*2;
    i {
      padding-right: $padding_small;
      vertical-align: bottom;
    }

    @include contentLists();
  }
  .budget-title {
    p {
      margin: 0px;
      padding: 0 5px 0 0;
      font-weight: 700;
    }
    span {
      color: $text_hint;
      font-size: $text_size_small;

    }
  }
  .field-owner,
  .field-permissions,
  .field-budget-items {
    p {
      margin: 0 0 5px 0;
    }
    .info {
      margin-left: 34px;
      max-width: 300px;
      margin-top: 10px;
    }
  }
  .field-owner {
    span {
      margin-left: -0.25em;
    }
  }
  .add-permission {
    .btn {
      min-width: 36px;
      bottom: 32px;
    }
  }
}

// budget item region
.budget__item-block {
  position: relative;
  padding-bottom: 76px;
  .add-permission {
    .btn {
      right: $padding_default*2;
    }
  }
  .change-item-block {
    @include box_shadow(3);
    clear: both;
    overflow: hidden;
    background-color: $primary_light;
  }
  .item-title {
    width: 50%;
    display: block;
    float: left;
    color: $white;
    font-weight: 700;
    opacity: 0.87;
    height: 50px;
    line-height: 55px;
    text-transform: uppercase;
    text-align: center;
    &:active,
    &:focus {

    }
  }
  a.active {
    box-shadow: inset 0 -4px 0 $accent;
    opacity: 1;
  }
  .add-permission {
    right: 0;
    bottom: $padding_default;
  }
  .budget-item-list {

  }
  .item-list-container {
    .disabled {
      color: $text_hint;
    }
    &:last-child {
      border-bottom: 1px solid $divider;
    }
    .btn {
      margin-top: -6px;
      margin-right: 8px;
    }
  }
  .child {
    span {
      cursor: pointer;
      &.child-name {
        display: inline-block;
        max-width: 85%;
        width: 85%;

        overflow-wrap: break-word;
      }
    }
  }
  .child-group {
    position: absolute;
    float: right;
    right: 20px;
    width: 80px;
    text-align: left;

    .up-wrap, .down-wrap, .arrow-wrap {
      width: 24px;
    }
  ,

    .arrow_down, .arrow_up {
      float: right;
    }
  }
  li {
    padding: 12px 0;
    font-weight: bold;
    border-top: 1px solid $divider;

    &:hover {
      background-color: rgba($text_hint, 0.20);
    }
    &:active, &:focus {
      background-color: rgba($text_hint, 0.20);
    }
    &.active {
      background-color: rgba($text_hint, 0.25);
    }
  }
  ul {
    margin: 0;
    list-style-type: none;
  }
}

.modal_edit-budget {
  .modal-content {
    p {
      margin-bottom: 0;
    }
  }
}