* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: $text_size_medium;
}

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
}

ul {
  padding: 0;
  margin: 0;
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  outline: 0;
  &:hover, &:focus {
    text-decoration: none;
    outline: 0;
  }
}

.layout_wrapper {
  position: absolute;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 1200px;
}

.content-wrapper {
  position: absolute;
  top: 112px;
  left: 0;
  height: calc(100% - 112px);
  width: 100%;
}

// float fix
.header-inner,
.content,
.contet_left-block,
.content-right-block,
.footer {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.clear {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}
.clear-b {
  clear: both;
}

.default-text {
  padding: $padding_default;
  font-weight: bold;
}

// ******************************************************
//                    ATOMIC CLASSES                     
// ******************************************************
// yahoo! css style
.f-300 {
  font-weight: 300;
}
.f-400 {
  font-weight: 400;
}
.f-500 {
  font-weight: 500;
}
.f-700 {
  font-weight: 700;
}

.divider {
  background-color: $divider;
  height: 1px;
  margin: $padding_default 0;
}

// #
// # Margins
// # 

.mg-t--6px {
  margin-top: -6px;
}

.mg-0 {
  margin: 0;
}

// #

.mg-t-0 {
  margin-top: 0;
}

.mg-t-4 {
  margin-top: 4px;
}

.mg-t-8 {
  margin-top: 8px;
}

.mg-t-16 {
  margin-top: 16px;
}

// #

.mg-r-8 {
  margin-right: 8px;
}

.mg-r-16 {
  margin-right: 16px;
}

// #

.mg-b-0 {
  margin-bottom: 0;
}

.mg-b-8 {
  margin-bottom: 8px;
}
.mg-b-12 {
  margin-bottom: 12px;
}
.mg-b-16 {
  margin-bottom: 16px;
}

.mg-t-32 {
  margin-top: 32px;
}

.mg-t-48 {
  margin-top: 48px;
}
// #

.mg-l-8 {
  margin-left: 8px;
}

.mg-l-24 {
  margin-left: 24px;
}

.mg-l-32 {
  margin-left: 32px;
}

// # 
// # Paddings
// # 

.pd-t-32 {
  padding-top: 32px;
}

.pd-t-16 {
  padding-top: 16px;
}

.pd-t-8 {
  padding-top: 8px;
}

// #

.pd-r-16 {
  padding-right: 16px;
}

.pd-r-8 {
  padding-right: 8px;
}

// #

.pd-b-16 {
  padding-bottom: 16px;
}

.pd-b-8 {
  padding-bottom: 8px;
}

// #

.pd-l-32 {
  padding-left: 32px;
}

.pd-l-16 {
  padding-left: 16px;
}

// #

.pd-t-b-16 {
  padding: 16px 0;
}

.pd-t-b-8 {
  padding: 8px 0;
}

// for hover effect
.btn-h {
  .btn {
    visibility: hidden;
  }
}

/* ==========  Select Field Variables ========== */
$color-black: "0,0,0";
$select-color: $text_primary;
$select-background-color: transparent;
$select-border-color: $divider;
$select-font-size: 18px;
$select-padding: 4px;

/* ==========  Select Field ========== */

/* Style Select Field */
select {
  background-color: transparent;
  width: 110%;
  border: none;
  outline: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 12px 0 12px 6px;
  border-bottom: 1px solid #B6B6B6;
  overflow: hidden;
  &.active {
    background-color: $primary-light;
    color: #fff;
    span {
      color: #fff;
    }
  }
}

.option {
  height: 20px;
  padding: 5px 0 5px 10px;
  &:hover {
    background-color: rgba($text_hint, 0.20);
  }
  &:active, &:focus {
    background-color: rgba($text_hint, 0.20);
  }
}



#addNewBudgetItem,
#editBudgetItem,
#transactions_new_transaction_budget_items_region,
#transactions_edit_transaction_budget_items_region,
#transactions_budget_item_filter_region,
#planning_edit_budget_item_parent_selection_region {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    padding: 8px 0 8px 8px;
    display: block;
    cursor: pointer;
    background-color: rgba($text_hint, 0.03);
    //border-bottom: 1px solid #B6B6B6;
    color: $primary-dark;
    &:hover {
      background-color: rgba($text_hint, 0.20);
    }
    &:active, &:focus {
      background-color: rgba($text_hint, 0.20);
    }
    &.active {
      background-color: rgba($text_hint, 0.30);
    }
  }
}

// ---------------------------------------------------
#budgets_budget_items_region {
  ul > li {
    padding-left: 40px;
  }
  ul ul > li {
    padding-left: 80px;
  }
  ul ul ul > li {
    padding-left: 120px;
  }
  ul ul ul ul > li {
    padding-left: 160px;
  }
  ul ul ul ul ul > li {
    padding-left: 200px;
  }
  ul ul ul ul ul ul > li {
    padding-left: 240px;
  }
}

#addNewBudgetItem,
#editBudgetItem,
#transactions_new_transaction_budget_items_region,
#transactions_edit_transaction_budget_items_region,
#transactions_budget_item_filter_region,
#planning_tree_region,
#planning_edit_budget_item_parent_selection_region {
  ul > li {
    padding-left: 30px;
  }
  ul ul > li {
    padding-left: 60px;
  }
  ul ul ul > li {
    padding-left: 90px;
  }
  ul ul ul ul > li {
    padding-left: 120px;
  }
  ul ul ul ul ul > li {
    padding-left: 150px;
  }
  ul ul ul ul ul ul > li {
    padding-left: 180px;
  }

  li.active {
    background-color: rgba($text_hint, 0.30);
  }
}

.inline {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.full-width {
  width: 100%;
}

.pd-4 {
  padding: 4px;
}

.error {
  color: $text_hint_error !important;
}

.btn-text {
  display: inline-block;
  padding-left: 8px;
  vertical-align: top;
}