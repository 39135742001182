html,
body {
  min-height: 100%;
}

.humane,
.humane-jackedup {
  position: fixed;
  transition: all 0.6s ease-in-out;
  z-index: 100000;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}

.humane,
.humane-jackedup {
  font-family: Helvetica Neue, Helvetica, san-serif;
  font-size: 18px;
  letter-spacing: -1px;
  top: 20px;
  left: 30%;
  opacity: 0;
  width: 40%;
  color: #fff;
  padding: 10px;
  text-align: center;
  background-color: #000;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  transform: translateY(-100px);
}

.humane p,
.humane-jackedup p,
.humane ul,
.humane-jackedup ul {
  margin: 0;
  padding: 0;
}

.humane ul,
.humane-jackedup ul {
  list-style: none;
}

.humane.humane-jackedup-info,
.humane-jackedup.humane-jackedup-info {
  *background-color: #000;
  color: #fff;
}

.humane.humane-jackedup-success,
.humane-jackedup.humane-jackedup-success {
  color: #fff;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.35);
}

.humane.humane-jackedup-error,
.humane-jackedup.humane-jackedup-error {
  background-color: #ee5f5b;
  color: #fff;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.35);
}

.humane-animate,
.humane-jackedup.humane-jackedup-animate {
  opacity: 1;
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.humane-animate:hover,
.humane-jackedup.humane-jackedup-animate:hover {
  opacity: 0.7;
}

.humane-js-animate,
.humane-jackedup.humane-jackedup-js-animate {
  opacity: 1;
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.humane-js-animate:hover,
.humane-jackedup.humane-jackedup-js-animate:hover {
  opacity: 0.7;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
}
