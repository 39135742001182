@import "../variables/_color-theme";

// Font-color
// ==========
.text-white {
  color: $text_white !important;
}

.text-primary {
  color: $text_primary !important;
}

.text-primary-blue {
  color: $primary !important;
}

.text-secondary {
  color: $text_secondary !important;
}

.text-success {
  color: $green !important;
}

.text-accent {
  color: $accent !important;
}

.text-error {
  color: $red !important;
}

.text-warning {
  color: $orange !important;
}

.text-accent {
  color: $accent !important;
}

.text-hint {
  color: $text_hint !important;
}

.text-hint-error {
  color: $text_hint_error !important;
}

// Background-color
// ================
.bg-white {
  background-color: $white !important;
}

.bg-primary {
  background-color: $primary !important;
}

.bg-primary-dark {
  background-color: $primary_dark !important;
}

.bg-primary-light {
  background-color: $primary_light !important;
}

.bg-error {
  background-color: $red !important;
}

.bg-error-hint {
  background-color: $text_hint_error !important;
}

.bg-warning {
  background-color: $orange !important;
}

.bg-success {
  background-color: $green !important;
}

.bg-accent {
  background-color: $accent !important;
}

.bg-divider {
  background-color: $divider !important;
}