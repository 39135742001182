// =================================================
// FRIENDS
// =================================================
.friends {
  main {
    padding: $padding_default $padding_small 0 $padding_default;
  }
  .status_name {
    display: block;
  }
  // right block container
  .right-block_top {
    position: relative;
    height: calc(100% - 92px);
    overflow: auto;
    padding-top: 15px;
  }
  // right bottom container
  .right-block_bottom {
    height: 72px;
    position: relative;
  }
  .avatar-img {
    float: left;
    img {
      margin: 0 $padding_default $padding_default;
      height: 200px;
      width: 200px;
      padding: 2px;
      border: 1px solid $divider;
      border-radius: 4px;
    }
  }
  // Friend block region
  .friend-block {
    padding: 0 $padding_default*2 0 $padding_default;
    margin-left: 216px;
  }
  // Friend name,info,status
  .friend-title {
    p {
      margin: 15px 0 5px;
    }
    .friend-name {
      font-weight: 700;
    }
    .friend-status {
      float: right;
      margin-left: 20%;
      margin-right: $padding_default;
    }
    .nickname {
      font-size: $text_size_small;
      color: $text_hint;
    }
  }
  // Friend permission block
  .permission-block {
    margin-bottom: 20px;
    .title {
      padding-top: 10px;
      margin: 0;
      color: $text_secondary;
      font-weight: 500;
    }
  }
  .permission-item {
    margin-top: 15px;
    padding: 10px;

    &.permission-item {
      &:hover, &:focus, &:active {
        background-color: rgba($text_hint, 0.20);
      }
    }

    .title {
      margin: 0px;
    }
    p.title {
      color: $text_primary;
    }
    .options {
      margin: 0 0 0 36px;
    }
    i {
      margin-right: $padding_small;
      vertical-align: bottom;
    }
    span {
      color: $text_hint;
    }
    span + span {
      &::before {
        content: ', ';
      }
    }
  }
  // remove from friends right bottom section
  .remove-friend {
    position: absolute;
    background-color: #fff;
    padding-right: $padding_default;
    right: $padding_default;
    bottom: $padding_default;
    width: 100%;
    .divider {
      width: calc(100% - 32px);
      float: right;
      margin: $padding_small 0;
    }
  }

  .add-permission {
    .btn {
      position: fixed;
      right: $padding_default*2;
      bottom: 85px;
      min-width: 200px;
    }
  }
}

//FRIENDS PAGE MARKER
.add-friend-code {
  h3 {
    text-transform: uppercase;
    font-weight: 400;
  }
  span {
    display: block;
    font-weight: 700;
    text-align: center;
    font-size: $text_size_large;
    margin: 25px 0;
  }
}

// ******************************************************
#grantAccessFromFriend {
  p {
    margin-bottom: 8px;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  a {
    display: block;
    padding: 8px 16px;
    -webkit-transition: all 2s;
    transition: all 0.2s;
    &:hover, &:focus {
      background-color: rgba($text_hint, 0.20);
    }
  }
  i {
    font-size: 20px;
    margin-top: 8px;
    margin-right: 10px;
    margin-left: 10px;
    float: left;
  }
}

.edit-permissions-for {
  display: inline-block;
}

.edit-permissions-name {
  display: inline-block;
}

.modal-body {
  h3 {
    margin-bottom: 0;
  }
}

.account-icon, .budget-icon{
  .material-icons{
    color: $text_primary;
  }
}