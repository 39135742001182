// Main color palette
// ==================

// White color
$white: #ffffff;
//Main color for buttons, lines, colored backgrounds and selectors
$primary: #1565C0;
//Lighter main color modification
$primary_dark: #0D47A1;
//primary_light Darken main color modification
$primary_light: #1E88E5;
//Color for accents
$accent: #4CAF50;
//Main text color
$text_primary: #212121;
//Color for secondary text
$text_secondary: #727272;
//Color for white text
$text_white: #FFFFFF;
//Icons color on not-white background
$icons: #FFFFFF;
//List dividers
$divider: #B6B6B6;
//Hint in text fields or hint labels
$text_hint: #ACACAC;
//Hint text color for errors
$text_hint_error: #E43030;

$selected_item: #268888;

// Additional color
// ================= 

//Used for errors or something negative
$red: #F44336;
//Used for something positive
$green: #4CAF50;
//Used for something undefined, awaiting
$orange: #FF9800;

$blue: #0D47A1;

$money: #0D47A1;

$gray: #888888;



