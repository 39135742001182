/*
# ================================
#             Switcher
# ================================
*/
.sw-checkbox {
  font-size: 1px !important;
  position: relative;
  display: inline-block;
  width: 44em;
  height: 24em;
}

.sw-checkbox input {
  display: none;
}

.sw-checkbox .switcher {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ddd;
  transition: .3s;
}

.sw-checkbox .switcher:before {
  position: absolute;
  content: '';
  height: 16em;
  width: 16em;
  left: 4em;
  bottom: 4em;
  background-color: #fff;
  transition: .3s;
}

.sw-checkbox input:checked + .switcher {
  background-color: $primary;
}

.sw-checkbox input:focus + .switcher {
  box-shadow: 0 0 1em $primary;
}

.sw-checkbox input:checked + .switcher:before {
  transform: translateX(18em);
}

.sw-checkbox .switcher.round {
  border-radius: 16em;
}

.sw-checkbox .switcher.round:before {
  border-radius: 16em;
}

.sw-checkbox.green input:checked + .switcher {
  background-color: $green;
}

.sw-checkbox.green input:focus + .switcher {
  box-shadow: 0 0 1em $green;
}

/*
# ================================
#             Checkmark
# ================================
*/
.chm-checkbox {
  font-size: 1px !important;
  position: relative;
  display: inline-block;
  width: 16em;
  height: 16em;
}

.chm-checkbox input {
  display: none;
}

.chm-checkbox .checkmark {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2em solid #ddd;
  border-radius: 2em;
  transition: .3s;
}

.chm-checkbox input:checked + .checkmark {
  background-color: $primary;
  border: 2em solid $primary;
}

.chm-checkbox input:checked + .checkmark:after {
  position: absolute;
  left: 4em;
  bottom: 2em;
  display: block;
  content: '';
  width: 4em;
  height: 10em;
  border: solid #fff;
  border-width: 0 2em 2em 0;
  transform: rotate(45deg);
  transition: .3s;
}

/*
# ================================
#             Radio
# ================================
*/
.rad-checkbox {
  font-size: 1px !important;
  position: relative;
  display: inline-block;
  width: 16em;
  height: 16em;
}

.rad-checkbox input {
  display: none;
}

.rad-checkbox .radio {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 16em;
  height: 16em;
  border-radius: 16em;
  border: 2em solid #ddd;
  transition: .3s;
}

.rad-checkbox input:checked + .radio {
  border: 2em solid blue;
}

.rad-checkbox input:checked + .radio:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  content: '';
  width: 8em;
  height: 8em;
  border-radius: 8em;
  transition: .3s;
  background-color: blue;
}

.new-account-modal .rad-checkbox input:checked + .radio {
  border: 2em solid $accent;
}

.new-account-modal .rad-checkbox input:checked + .radio:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  content: '';
  width: 8em;
  height: 8em;
  border-radius: 8em;
  transition: .3s;
  background-color: $accent;
}