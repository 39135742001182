// new style for modal window
.modal h3 {
  margin-top: 8px;
}

.modal-header {
  padding: 16px 24px;
}

.modal-body {
  padding: 24px 24px 0px;
}

.modal-footer {
  padding: 16px 24px;
  border-top: 0px;
}

.modal-sm {
  width: 348px;
}

// animation effect for modal window
.modal.fade .modal-dialog {
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
  top: 300px;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.modal.fade.in .modal-dialog {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform: translate3d(0, -300px, 0);
  transform: translate3d(0, -300px, 0);
  opacity: 1;
}

.alert-modal {
  .modal-header {
    border-bottom: 0;
    background-color: $primary;
    text-align: center;
    color: $white;
    font-size: $text_size_large;
  }
  .modal-body {
    text-align: center;
    padding-bottom: 20px;
    p {
      margin: 10px;
    }
  }
  .modal-footer {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, .1);
  }
}

// context nav dropdown menu stylse (bootstrap dropdown)
.context-nav {
  .dropdown-menu {
    min-width: 220px;
    a {
      padding: 16px;
      &:active, &:focus {
        background-color: rgba($text_hint, 0.20);
      }
    }
    a::after {
      content: '';
      display: table;
      clear: both;
    }
    input[type='checkbox'] {
      display: inline-block;
      margin-top: 2px;
    }
    .disabled > a,
    .disabled > a:hover,
    .disabled > a:focus {
      color: $text_secondary;
      font-weight: 500;
    }
    .disabled > a:hover,
    .disabled > a:focus {
      cursor: default;
    }
  }
}
