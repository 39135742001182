@import "../variables/_sizing";

/* Shadow mixin
  ============= */

@mixin box_shadow($level) {
  @if $level == 2 {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0 3px 1px -2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0 1px 5px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
  } @else if $level == 3 {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0 3px 3px -2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
    0 1px 8px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
  } @else if $level == 4 {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0 1px 10px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
    0 2px 4px -1px rgba(0, 0, 0, $shadow-key-penumbra-opacity);
  } @else if $level == 6 {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0 1px 18px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
    0 3px 5px -1px rgba(0, 0, 0, $shadow-key-penumbra-opacity);
  } @else if $level == 8 {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0 3px 14px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
    0 5px 5px -3px rgba(0, 0, 0, $shadow-key-penumbra-opacity);
  } @else if $level == 16 {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
    0 6px 30px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
    0 8px 10px -5px rgba(0, 0, 0, $shadow-key-penumbra-opacity);
  }
}

/* Shadow class
  ============= */

.box-shadow-2px {
  @include box_shadow(2);
}

.box-shadow-3px {
  @include box_shadow(3);
}

.box-shadow-4px {
  @include box_shadow(4);
}

.box-shadow-6px {
  @include box_shadow(6);
}

.box-shadow-8px {
  @include box_shadow(8);
}

.box-shadow-16px {
  @include box_shadow(16);
}

.material-box-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}