.dashboard {
  position: relative;
  height: 100%;
  width: 100%;
  // progress content block
  .progress-content {
    position: relative;
    height: 100%;
    width: calc(100% - 370px);
    display: inline-block;
    vertical-align: top;

    .overview-plan-content {
      padding: 16px 0 0 16px;

      i {
        vertical-align: top;
        margin-top: 10px;
      }

      span > select {
        margin-left: 16px;
      }

      a {
        margin-top: 4px;
      }
    }
    small {
      color: $text_secondary;
      font-weight: 500;
    }
  }

  .incomes, .expenses {
    h2 {
      margin: 8px 0 16px;
      i {
        margin-left: -8px;
        vertical-align: top;
        font-size: 34px;
      }
    }
    p {
      font-weight: 700;
      color: $primary;
      margin: 4px 0;
      &::after {
        content: ' ';
        display: table;
        clear: both;
      }
    }
    .pull-left {
      i {
        color: $text_primary;
        vertical-align: bottom;
        margin-right: 4px;
      }
    }
  }

  // sidebar navigation block
  .sidebar-nav {
    position: relative;
    display: inline-block;
    height: 100%;
    overflow: auto;
    width: 365px;
    border-left: 1px solid $divider;
    h2 {
      margin: 0 0 16px 16px;
      font-weight: 500;
    }
    .group {
      padding: 8px;
      margin: 0px 12px;
      border-bottom: 1px solid $divider;
      cursor: pointer;

      &.empty {
        padding: 8px 16px;
      }

      &:hover {
        background-color: rgba($text_hint, 0.20);
      }
      &:active, &:focus {
        background-color: rgba($text_hint, 0.20);
      }

      &.active {
        background-color: rgba($text_hint, 0.25);
      }
    }
    .pull-right {
      color: $text_secondary;
    }
    .item-name {
      color: $text_primary;
    }
    .item-value {
      color: $primary;
    }
  }
}

#overview_budgets_selector, #overview_budget_plans_selector {
  width: 300px;
  display: inline-block;
  margin-right: 16px;
  margin-left: 32px;
}

.sidebar-nav {
  ul {
    list-style-type: none;
    padding: $padding_default 0 0 0;
  }
  // class for every list item
  .item {
    padding: $padding_default;
    display: block;
    color: $text_primary;
    font-weight: 500;
    word-wrap: break-word;
    border-bottom: 1px solid #B6B6B6;

    &:hover {
      background-color: rgba($text_hint, 0.20);
    }
    &:active, &:focus {
      background-color: rgba($text_hint, 0.20);
    }

    &.active {
      background-color: rgba($text_hint, 0.25);
    }
    &::after {
      content: '';
      display: table;
      clear: both;
    }

    li {
      position: relative;
    }
    i {
      position: absolute;
      right: $padding_default;
      top: 50%;
      margin-top: -12px;
    }
    .account-name, i {
      color: $text-primary;
    }
    .account-name,
    .account-count {
      display: inline-block;
      width: 50%;
      word-wrap: break-word;
      font-weight: bold;
    }
    .account-name {
      line-height: 1.1em;
    }
    .account-count {
      color: $primary;
      margin-top: $padding_small;
    }

    .owner-name {
      line-height: 1.1em;
    }

  }

  .transaction-item {
    display: inline-block;
    margin: 0;
    word-break: normal;

    &.default-text {
      padding: 2px;
    }

    &.pull-left {
      width: 95%;
    }
  }

  .cancelled {
    color: $text_hint !important;
  }

  .description {
    display: inline-block;
    width: 100%;
    //hack to show 2 lines description
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.col-90 {
  .major-progress-bar .current-item-amounts {
    cursor: default;

    & > .progress-group {
      cursor: default !important;
    }
  }
}

.edit-budget-plan-item-modal {
  padding: 16px 24px 24px;
  @include editItemAmountsTable();
}
