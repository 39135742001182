@import "../variables/_sizing";

// text-align
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

// font-size
.text-hint-small {
  font-size: $text_size_hint;
}

.text-small {
  font-size: $text_size_small;
}

.text-medium {
  font-size: $text_size_medium;
}

.text-large {
  font-size: $text_size_large;
}

.text-title {
  font-size: $text_size_title;
}

.text-300 {
  font-weight: 300;
}

.text-400 {
  font-weight: 400;
}

.text-500 {
  font-weight: 500;
}

.text-700 {
  font-weight: 700;
}

