// ========================================================================================================
//                                                   HEADER
// ========================================================================================================
.header-inner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  @include box_shadow(6);
  width: 100%;
}

.header-inner__title,
.header-inner__nav {
  height: 56px;
  line-height: 56px;
  padding: 0 $padding_default;
  i {
    vertical-align: middle;
  }
}

.header-inner__nav {
  a {
    color: $text_white;
  }
}
// =========================================================================================================
//                                                HEADER TITLE
// =========================================================================================================
.header-inner__title {
  background-color: $primary_dark;
  font-size: $text_size_large;
  // brand icon and company name
  .brand {
    display: inline-block;

    i {
      padding-right: $padding_small;
    }
    .brand-logo {
      font-weight: 400;
      display: inline-block;
      padding: 0 $padding_default;
      color: $text-white;

      &:active, &:focus {
        background-color: darken($primary_dark, 7%);
      }
    }

    .logo-title {
      font-weight: 700;
      color: $text-white;
    }

    .budget-selector-wrapper {
      display: inline-block;
      width: auto;
      font-size: $text_size_medium;
      max-width: 300px;
      max-height: 56px;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
      vertical-align: top;
      cursor: pointer;

      .budget-selector {
        color: white;
        max-height: 56px;
        margin: 0;

        span {
          display: inline-block;
          max-width: 85%;
        }

        .material-icons {
          padding-top: 15px;
        }
      }

      .budgets-selector-list {
        list-style: none;
        margin: 0;
      }

      .dropdown-content > .budget-selector-checkbox {
        padding: 12px 16px 0 16px;
        border-bottom: 1px solid $divider;
      }
    }

    .budget-selector-wrapper > .dropdown-content > a {
      display: inline-block;
      line-height: 100%;
      padding: 12px 16px;

      &.text-hint {
        color: $text_hint !important;
      }

    }
  }
  // user name, settings icon and dropdown container
  .user-settings {
    float: right;
    font-size: $text_size_medium;
    padding: 0 $padding_small;

    span {
      margin-left: 8px;
    }

    a {
      display: block;
      padding: 0px $padding_default;

      &:active, &:focus {
        background-color: darken($primary_dark, 7%);
      }
    }
  }
  .dropdown-block > a > i {
    padding-left: $padding_small;
  }

  .header-icon {
    color: white;
    cursor: pointer;
  }
}

// ========================================================================================================
//                                                HEADER NAVIGATION
// ========================================================================================================
.header-inner__nav {
  background-color: $primary;
  font-size: $text_size_medium;
  // primary navigation menu
  .main-nav {
    float: left;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: inline-block;
      margin-right: 4px;
    }
    a {
      display: block;
      padding: 0 $padding_default;
      min-width: 64px;
      font-weight: 700;
      opacity: 0.87;
      &:active, &:focus {
        background-color: darken($primary, 7%);
      }
      &.active {
        box-shadow: inset 0 -4px 0 $accent;
        opacity: 1;
      }

    }
  }
  // right context menu icon and dropdown container
  .context-nav {
    float: right;
    position: relative;
    display: block;
    padding: 0 $padding_small;
    button {
      color: $white;
      padding: 0;
      width: 36px;
      min-width: 36px;
      border-radius: 36px;
    }
  }
  .context-nav > a {
    display: inline-block;
    padding: 0 10px;
  }
  .context-nav > a:focus {
    background-color: darken($primary, 7%);

  }
}

// ====================================================================================================
//                                                 LEFT BLOCK
// ====================================================================================================
// left section for list menu
.content__left-block {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  height: 100%;
  width: 300px;
  overflow-y: auto;
  @include box_shadow(6);
  // list menu container
  .left-block_top {
    position: relative;
    overflow-y: auto;
    height: calc(100% - 78px);
    li {
      border-bottom: 1px solid $divider;
      position: relative;
      &.active {
        background-color: rgba($text_hint, 0.30);
      }
    }
    a {
      padding-right: 16px;
    }
    i {
      position: absolute;
      right: $padding_default;
      top: 50%;
      margin-top: -12px;
    }
  }
  ul {
    list-style-type: none;
    padding: $padding_default 0 0 0;
    margin: 0 0 10px 0;
  }
  // class for every list item
  .item {
    padding: $padding_default;
    display: block;
    color: $text_primary;
    font-weight: 500;
    word-wrap: break-word;
    &:hover {
      background-color: rgba($text_hint, 0.20);
    }
    &:active, &:focus {
      background-color: rgba($text_hint, 0.20);
    }

    &.active {
      background-color: rgba($text_hint, 0.30);
    }
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
  // button add new list item container in left list menu
  .left-block_bottom {
    height: 78px;
    position: relative;
  }
  // button add new list item
  .btn {
    position: absolute;
    bottom: $padding_default + $padding_small;
    right: $padding_default;
  }
}

// ============================================================================================================
//                                                 RIGHT BLOCK
// ============================================================================================================
// container for right block
.content__right-block {
  position: absolute;
  left: 300px;
  height: 100%;
  width: calc(100% - 300px);
  overflow: auto;
}

// ============================================================================================================
//                                                  SIMILAR STYLES
// ============================================================================================================
//
// SWITCH LANGUAGE MODAL WINDOW
// ****************************
.choose-language {
  span {
    padding-left: 8px;
  }
  .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, .1);
  }
}

// styles for permission-block region
.budget__permission-block,
.account__permission-block {
  .title {
    background-color: $primary_light;
    height: 50px;
    line-height: 50px;
    text-align: center;
    padding: 0 24px;
    color: $white;
    width: 100%;
    font-size: $text_size_large;
    @include box_shadow(2);
  }
  // add permission button
  .add-permission {
    bottom: $padding_default;
    .btn {
      right: $padding_default*2;
    }
  }
}

// table inside permission region
table.permission-table {
  width: 100%;
  tr {
    border-bottom: 1px solid $divider;

    &.permission-item {
      &:hover, &:focus, &:active {
        background-color: rgba($text_hint, 0.25);
      }
    }
  }
  td, th {
    font-weight: bold;
    text-align: left;
    padding: 0 16px 0px 64px;
    height: 50px;
    line-height: 50px;
  }
}

// add permission button can be used in right bottom section to add something via modal window
.add-permission {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 76px;
  .btn {
    position: absolute;
    right: $padding_default*2;
    bottom: 0;
    min-width: 200px;
  }
}

.add-budget-item {
  position: fixed;
  right: 0;
  bottom: 24px;
  height: 76px;
  .btn {
    position: absolute;
    right: $padding_default*2;
    bottom: 0;
  }
}

.disabled {
  pointer-events: none;

  input {
    pointer-events: none;
  }

  select {
    pointer-events: none;
  }
}