.landing-header {
  position: relative;
  width: 100%;
  height: 56px;
  background-color: $primary;
  font-size: $text_size_large;
  a {
    color: $white;
  }
  .brand {
    line-height: 56px;
    float: left;
    padding: 0 $padding_default*2;
    i {
      vertical-align: middle;
      padding-right: $padding_small;
    }
    span {
      font-weight: 700;
    }
  }
  .context-nav {
    line-height: 56px;
    float: right;
    padding: 0;
    margin-right: $padding_default;
    a {
      display: inline-block;
    }
    i {
      vertical-align: middle;
    }
  }
  .current-language {
    position: relative;
    padding: 0px 8px;
    &:focus, &:active {
      background-color: darken($primary, 7%);
    }
    i {
      padding-right: 4px;
    }
  }
  .header-inner__nav {
    float: right;
    padding-right: 0px;
    .main-nav {
      a {
        opacity: 1;
      }
      .active {
        box-shadow: 0 0 0 transparent;
        color: $green;
      }
    }
  }
}

.landing-main {
  position: relative;
  width: 100%;
  height: calc(100% - 56px);
  background-color: #1E88E5;
  padding-top: 60px;
  .form-container {
    position: relative;
    width: 350px;
    max-height: 400px;
    margin: 0 auto;

    .google-signIn-btn {
      display: inline-block;
      width: 18px;
      height: 36px;
      vertical-align: top;

      g > path {
        fill: $primary;
      }

    }

    .form-text {
      display: block;
      color: $primary;
      text-align: center;
      text-transform: uppercase;
      padding: $padding-default/2;

      //&:before {
      //  display: block;
      //  content: " ";
      //  width: calc(100% - 24px);
      //  border-bottom: 1px solid $divider;
      //}
    }

    .form-header {
      background-color: $primary;
      text-align: center;
      height: 56px;
      line-height: 56px;
      a {
        display: block;
        float: left;
        width: 50%;
        color: $white;
        text-transform: uppercase;
        font-weight: 500;
        opacity: 0.87;
        &.active {
          box-shadow: inset 0 -4px 0 $green;
          opacity: 1;
        }
      }
    }
    .form-block {
      padding: 16px 16px 16px 24px;
      background-color: $white;
      label {
        display: block;
        color: $text_secondary;
        margin-bottom: 0;
        margin-top: $padding_small;
        cursor: default;
      }
    }
    .form-footer {
      text-align: center;
    }
  }
}