.planning {
  .left-block_top {
    span {
      display: block;
    }
    a {
      display: block;
      padding: 16px;
      color: $text_primary;
      &:hover {
        background-color: rgba($text_hint, 0.20);
      }
    }
    .name {
      font-weight: 500;
    }
  }
  .expiry-time {
    color: $text_secondary;
    font-size: 16px;
    margin: 0;
  }

  h2 {
    margin-top: 0;

    i {
      float: left;
      font-size: 28px;
      margin-right: 12px;
    }
  }

  .date-block {
    padding: 16px 24px 24px;
    margin-top: 32px;
    vertical-align: top;

    h3 {
      margin: 0 0 0 80px;
      font-weight: 700;
    }
    .select-block {
      padding-bottom: 16px;
      .name {
        font-weight: 500;
      }

      .form-group {
        input {
          text-align: center;
        }
      }
    }
  }

  .dates-table {
    padding: 16px 24px 24px;
    margin-top: 32px;
  }

  .transactions-list {
    vertical-align: top;
    margin-bottom: 32px;

    .title {
      background-color: $primary_light;
    }
    .item {
      color: $white;
      display: inline-block;
      padding: 12px 0;
      width: 49%;
      margin: 0;
      cursor: pointer;
      opacity: 0.84;
      &.active {
        font-weight: 500;
        opacity: 1;
        box-shadow: inset 0 -4px 0 #4CAF50;
      }
      span {
        display: block;
      }
      i {
        float: left;
        padding: 0px 24px 0 48px;
      }
    }
    .trans-content {
      overflow-y: auto;

      ul {
        margin: 0;
        padding: 0;
      }
      li {
        list-style-type: none;
        border-bottom: 1px solid #B6B6B6;
        line-height: 42px;
        padding: 0 16px;
        cursor: pointer;

        &:hover {
          background-color: rgba($text_hint, 0.20);
        }
        i {
          float: right;
          display: inline-block;
          font-size: 32px;
          margin-top: 6px;
          margin-left: 12px;
        }
      }

      .amounts, .child-name {
        width: calc(45% - 44px);
        word-break: normal;
      }

      .amount-wrapper {
        display: block;
      }

      .child-group {
        // reservate place for arrow
        width: 44px;
        height: 32px;
      }
    }
  }
  .edit-item-wrapper {
    padding: 16px 24px 24px;
  }

  @include editItemAmountsTable();
}