@import "../variables/_color-theme";
@import "../variables/_sizing";

.btn {
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: bold;
  background: transparent;
  color: $text_primary;
  border: none;
  border-radius: 2px;
  position: relative;
  display: inline-block;
  padding: 0px 16px;
  height: 36px;
  line-height: 36px;
  min-width: 64px;
  overflow: hidden;
  will-change: box-shadow, transform;
  -webkit-transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
  background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
  color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
  background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
  color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  &:active, &:focus {
    box-shadow: none;
    outline: 0;
  }
}

.btn-flat {

  &:active, &:focus {
    background: rgba($text_hint, 0.20);
  }

  // Additional buttons
  &.btn-primary {
    color: $primary;
  }
  &.btn-secondary {
    color: $text-secondary;
  }
  &.btn-success {
    color: $green;
  }
  &.btn-accent {
    color: $accent;
  }
  &.btn-warning {
    color: $orange;
  }
  &.btn-error {
    color: $red;
  }
  &.btn-hint-error {
    color: $text_hint_error;
  }

}

.btn-raised {
  @include box_shadow(2);
  &:active {
  }
  // Additional buttons
  &.btn-primary {
    color: $text_white;
    background-color: $primary;
    &:active, &:focus {
      background-color: darken($primary, 10%);
    }
  }
  &.btn-secondary {
    color: $text_white;
    background-color: $text_hint;
    &:active, &:focus {
      background-color: darken($text_hint, 10%);
    }
  }
  &.btn-success {
    color: $text_white;
    background-color: $green;
    &:active, &:focus {
      background-color: darken($green, 10%);
    }
  }
  &.btn-accent {
    color: $text_white;
    background-color: $accent;
    &:active, &:focus {
      background-color: darken($accent, 10%);
    }
  }
  &.btn-warning {
    color: $text_white;
    background-color: $orange;
    &:active, &:focus {
      background-color: darken($orange, 10%);
    }
  }
  &.btn-error {
    color: $text_white;
    background-color: $red;
    &:active, &:focus {
      background-color: darken($red, 10%);
    }
  }
  &.btn-hint-error {
    color: $text_white;
    background-color: $text_hint_error;
    &:active, &:focus {
      background-color: darken($text_hint_error, 10%);
    }
  }
}

.btn-float {
  border-radius: 50%;
  font-size: 24px;
  height: 46px;
  margin: auto;
  min-width: 46px;
  width: 46px;
  padding: 0;
  overflow: hidden;
  @include box_shadow(2);
  position: relative;
  line-height: normal;
  .material-icons {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-12px, -12px);
    -ms-transform: translate(-12px, -12px);
    transform: translate(-12px, -12px);
    line-height: 24px;
    width: 24px;
  }
  &:hover {
  }
  &:active {
  }
  // Additional buttons
  &.btn-primary {
    color: $text_white;
    background-color: $primary;
    &:active, &:focus {
      background-color: darken($primary, 10%);
    }
  }
  &.btn-secondary {
    color: $text_white;
    background-color: $text_hint;
    &:active, &:focus {
      background-color: darken($text_hint, 10%);
    }
  }
  &.btn-success {
    color: $text_white;
    background-color: $green;
    &:active, &:focus {
      background-color: darken($green, 10%);
    }
  }
  &.btn-accent {
    color: $text_white;
    background-color: $accent;
    &:active, &:focus {
      background-color: darken($accent, 10%);
    }
  }
  &.btn-warning {
    color: $text_white;
    background-color: $orange;
    &:active, &:focus {
      background-color: darken($orange, 10%);
    }
  }
  &.btn-error {
    color: $text_white;
    background-color: $red;
    &:active, &:focus {
      background-color: darken($red, 10%);
    }
  }
  &.btn-hint-error {
    color: $text_white;
    background-color: $text_hint_error;
    &:active, &:focus {
      background-color: darken($text_hint_error, 10%);
    }
  }
}